import React, { createContext, useState, useContext, useEffect } from 'react';
import queryString from 'query-string';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'; 

const getFromQueryString = key => {
    return queryString.parse(window.location.search)[key];
}

(!firebase.apps.length) && firebase.initializeApp({
    apiKey: "AIzaSyAF_YiKapEcsl8-VvZbFCpd1SpAXnUQlnE",
    authDomain: "arcmages.firebaseapp.com",
    databaseURL: "https://arcmages.firebaseio.com",
    projectId: "arcmages",
    storageBucket: "arcmages.appspot.com",
    messagingSenderId: "835087502247",
    appId: "1:835087502247:web:9f936765a023cea4ca8482",
    measurementId: "G-H6ZY18FP1S"
});

const googleProvider = new firebase.auth.GoogleAuthProvider();
//const facebookProvider = new firebase.auth.FacebookAuthProvider(); 

const authContext = createContext(); 
export const useAuth = () => useContext(authContext);

export function AuthProvider({ children }) {
    const auth = useProvideAuth(); 
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

function useProvideAuth() {
    const [initialized, setInitialized] = useState(() => false); 
    const [user, setUser] = useState(() => undefined); 


    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if(!initialized) return setInitialized(true); 
            setUser(user ? user : false); 
        });

        return () => unsubscribe();
    }, [initialized]);


    const signIn = (email, password) => {
        return firebase.auth().signInWithEmailAndPassword(email, password).then((response) => {
            setUser(response.user);
            return response.user; 
        });
    }

    const register = (email, password) => {
        return firebase.auth().createUserWithEmailAndPassword(email, password).then(response => {
            setUser(response.user);
            return response.user;
        });
    }

    const signOut = () => {
        return firebase.auth().signOut().then(() => {
            setUser(false);
        });
    }

    const sendPasswordResetEmail = email => {
        return firebase.auth().sendPasswordResetEmail(email).then(() => {
            return true;
        });
    }

    const confirmPasswordReset = (password, code) => {
        const resetCode = code || getFromQueryString('oobCode');
        return firebase.auth().confirmPasswordReset(resetCode, password).then(() => {
            return true;
        });
    }

    const connectGoogle = () => {
        return firebase.auth().signInWithPopup(googleProvider).then(function(result) {
            setUser(result.user);
            return result.user;
        });
    }

    return {
        user,
        initialized,
        signIn,
        register,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
        connectGoogle
    }
}


const userContext = createContext();
export const useUser = () => useContext(userContext);
export function UserProvider({ children }) {
    const user = useProvideUser();
    return <userContext.Provider value={user}>{children}</userContext.Provider>;
}


var userListeners = {}; 
function useProvideUser() {
    let [user, setUser] = useState(() => undefined); 
    let [friendRequests, setFriendRequests] = useState(() => []);
    let [friends, setFriends] = useState(() => []);
    let [gameInvites, setGameInvites] = useState(() => []);
    let [appearance, setAppearance] = useState(() => {});
    let [cosmeticUnlocks, setCosmeticUnlocks] = useState(() => {});
    let auth = useAuth(); 

    useEffect(() => {
        setGameInvites([]);
        setFriendRequests([]);
        if(auth.user) {
            if(!userListeners['main']) {
                userListeners['main'] = firebase.firestore().collection('users').doc(auth.user.uid).onSnapshot(async snapshot => {
                    let userData = snapshot.exists ? snapshot.data() : false; 
                    let token = await firebase.auth().currentUser.getIdToken(false); 
                    let result = await fetch('https://us-central1-arcmages.cloudfunctions.net/fetchCosmeticAvailability', {
                        method: 'POST',
                        body: JSON.stringify({ token })
                    });

                    setCosmeticUnlocks(await result.json());
                    firebase.firestore().collection('user_characters').doc(auth.user.uid).onSnapshot(snapshot => {
                        setAppearance(snapshot.data()); 
                        if(!user) setUser(userData);
                    });
                });
            }
        } else {
            Object.values(userListeners).forEach(unsubscribe => unsubscribe());
            userListeners = {};
        }
    }, [auth.user]); 

    useEffect(() => {
        if(user && auth.user && auth.user.uid) {
            if(!userListeners['friends']) userListeners['friends'] = firebase.firestore().collection('user_friends').where('parties', 'array-contains', auth.user.uid).onSnapshot(snapshot => {
                snapshot.docChanges().forEach(async (change) => {
                    if(change.type === 'added') {
                        if(friends.filter(f => f.id === change.doc.id).length === 0) {
                            let _fr = friends.slice();

                            let other = change.doc.data().parties.filter(p => p != auth.user.uid)[0]; 
                            let oSnap = await firebase.firestore().collection('users').doc(other).get(); 
                            
                            _fr.push({ id: change.doc.id, ...change.doc.data(), other: oSnap.exists ? oSnap.data() : {} });
                            setFriends(_fr);
                        }
                    } else if(change.type === 'removed') {
                        if(friends.filter(f => f.id === change.doc.id).length > 0) {
                            let _fr = friends.slice();
                            _fr = _fr.filter(f => f.id !== change.doc.id);
                            setFriends(_fr);
                        }
                    }
                });
            });
        } else if(!auth.user) {
            Object.values(userListeners).forEach(unsubscribe => unsubscribe());
            userListeners = {};
        }
    }, [auth.user, user, friends, friendRequests, gameInvites]);


    return user ? { ...user, appearance, cosmeticUnlocks, id: auth.user.uid, friends, friendRequests, gameInvites } : user;
}