import React from 'react';
import AppearanceSlots from '../../common/Character/AppearanceSlots.json';
import NullableSlots from '../../common/Character/NullableSlots.json';
import CharacterAssets from '../../common/Character/assets';
import styles from './Character.module.css';
import ColorPicker from './ColorPicker';


export default function ({ slot, selected, unlocks, update, updateTint }) {
    const [page, setPage] = React.useState(() => 1); 
    const nullable = NullableSlots.indexOf(slot) !== -1;
    let array = (nullable ? ['empty'] : []).concat(CharacterAssets[slot].slice(0 + (page > 1 ? (nullable ? -1 : 0) : 0), 10 + (nullable ? -1 : 0))); 
    if(array.length < 10) {
        for(let i = array.length; i < 10; i++) {
            array.push('');
        }
    }


    let startIndex = page === 1 ? 0 : (page > 1) ? page * 10 : 0;
    return (
        <div className={styles.assets}>
        <div className={styles.items}>
            <div className={styles.itemGroup} >
                { 
                    [...array.slice(startIndex, startIndex + 5)].map((asset, index) => {
                        let empty = !asset;
                        let none = asset === 'empty'; 
                        let id = startIndex + index + (nullable ? 0 : 1);
                        let exists = unlocks[slot][id] !== undefined;
                        let unlocked = !exists ? true : unlocks[slot][id];
                        let  _selected = id == selected.id; 

                        return <div className={styles.item + " " + (exists ? (unlocked ? (_selected ? styles.selected : undefined) : styles.locked) : styles.empty)} key={(index * page)} onClick={() => exists ? update(slot, id) : {}}>
                            { none && <img className={styles.preview} src="https://icon-library.com/images/not-allowed-icon/not-allowed-icon-4.jpg" alt="" /> }
                            { none == false && empty == false && <img className={styles.preview} src={asset} alt="" /> }
                        </div>
                    }) 
                }
            </div>
            <div className={styles.itemGroup} style={{ marginTop: 20 }}>
                { 
                    [...array.slice(startIndex + 5, startIndex + 11)].map((asset, index) => {
                        let empty = !asset;
                        let none = asset === 'empty'; 
                        let id = 5 + startIndex + index + (nullable ? 0 : 1);
                        let exists = unlocks[slot][id] !== undefined;
                        let unlocked = !exists ? true : unlocks[slot][id];
                        let _selected = id == selected.id; 

                        return <div className={styles.item + " " + (exists ? (unlocked ? (_selected ? styles.selected : undefined) : styles.locked) : styles.empty)} key={(index * page)} onClick={() => exists ? update(slot, id) : {}}>
                            { none && <img className={styles.preview} src="https://icon-library.com/images/not-allowed-icon/not-allowed-icon-4.jpg" alt="" /> }
                            { none == false && empty == false && <img className={styles.preview} src={asset} alt="" /> }
                        </div>
                    }) 
                }
            </div>
        </div>
        <div className={styles.colorPickers}>
            <ColorPicker label="Primary" value={selected.primary} updateTint={(rgb) => updateTint(slot, 'primary', rgb)} />
            <ColorPicker label="Secondary" value={selected.secondary} updateTint={(rgb) => updateTint(slot, 'secondary', rgb)} />
            <ColorPicker label="Tertiary" value={selected.tertiary} updateTint={(rgb) => updateTint(slot, 'tertiary', rgb)} />
        </div>
        </div>
    )
}