import React, { useState, useEffect } from 'react';
import ArrowRight from '../../svg/ArrowRightIcon';
import SubmitBox from '../../svg/ButtonSquare';
import GoogleIcon from '../../svg/Google';
import FacebookIcon from '../../svg/Facebook';
import styles from './Connect.module.css';
import { useAuth } from '../../hooks/firebase';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

export default function Connect() {
    const [isLogin, setIsLogin] = React.useState(() => true);
    const [error, setError] = React.useState(() => '');

    const [width, setWidth] = useState(() => typeof window !== 'undefined' ? window.innerWidth : 0);
    const [email, setEmail] = useState(() => ''); 
    const [emailConfirm, setEmailConfirm] = useState(() => ''); 
    const [password, setPassword] = useState(() => ''); 
    const [passwordConfirm, setPasswordConfirm] = useState(() => ''); 
    const [canShowVideo, setCanShowVideo] = useState(() => false); 

    const [videoLoaded, setVideoLoaded] = useState(() => false);
    const auth = useAuth();
    const history = useHistory(); 
    
    useEffect(() => {
        const onResize = () => setWidth(window.innerWidth); 
        window.addEventListener('resize', onResize);
        setCanShowVideo(window && window.innerWidth > 900);
        return () => window.removeEventListener('resize', onResize); 
    }, []); 
    
    
    const validateConfirm = (a, b) => a == b; 
    const validatePassword = () => password.length > 5 && password.length < 65;

    const login = () => auth.signIn(email, password).then(() => {
        history.push('/');
    }).catch(_error => {
        if(_error.code == 'auth/user-not-found') setError('Could not find a user with the provided email & password.');
        else setError(_error.message);
    });

    const google = (e) => {
        e.preventDefault();
        auth.connectGoogle().then(() => {
            history.push('/');
        }).catch(_error => {
            setError(_error.message);
        });
    }

    const register = () => {
        if(!validator.isEmail(email)) return setError('Please enter a valid email address.');
        if(!validatePassword()) return setError('Please enter a password between 6 and 64 characters.'); 
        if(!validateConfirm(email, emailConfirm)) return setError('Email confirmation does not match.');
        if(!validateConfirm(password, passwordConfirm)) return setError('Password confirmation does not match.');

        auth.register(email, password).then(() => {
            history.push('/');
        }).catch(_error => {
            setError(_error.message);
        })
    }



    return (
        <div className={styles.page}>
            <div className={styles.connect}>
                <div className={styles.content}>
                    <div className={styles.form}>
                        <h1>SIGN IN WITH YOUR <span>BETA</span><br />ACCOUNT</h1>

                        <p className={styles.error}>{error}</p>

                        <input type="text" placeholder="Enter Email" className={(!isLogin) ? styles.registration : undefined} onChange={e => setEmail(e.target.value)} />
                        <a href="/" className={(!isLogin) ? styles.hide : undefined}>Forgot email ?</a>

                        <input type={isLogin ? "password" : "text"} placeholder={isLogin ? "Enter Password" : "Confirm Email"} className={(!isLogin) ? styles.registration : undefined} onChange={e => isLogin ? setPassword(e.target.value) : setEmailConfirm(e.target.value)}/>
                        <a href="/" className={(!isLogin) ? styles.hide : undefined}>Forgot password ?</a>

                        <div className={styles.formExtras + " " + (isLogin ? styles.hidden : undefined)}>
                            <input type="password" placeholder="Enter Password" onChange={e => setPassword(e.target.value)}/>
                            <input type="password" placeholder="Confirm Password" onChange={e => setPasswordConfirm(e.target.value)} />
                        </div>

                        <div className={styles.submitContainer + " " + styles.web} onClick={isLogin ? login : register}>
                            <div className={styles.submit}>
                                <ArrowRight className={styles.arrow} />
                                <SubmitBox className={styles.box} />
                            </div>
                        </div>

                        <a href="/" onClick={(e) => { e.preventDefault(); setIsLogin(prev => !prev)}} className={styles.switch}>{isLogin ? 'Create Account' : 'Already Have An Account?'}</a>
                    </div>

                    <div className={styles.seperator}>
                        <div className={styles.line} />
                        <div>OR</div>
                        <div className={styles.line} />
                    </div>

                    <div className={styles.socials}>
                        <div className={styles.social} onClick={auth.connectGoogle}>
                            Connect with <GoogleIcon className={styles.svg} />
                        </div>

                        <div className={styles.social + " " + styles.disabled}>
                            Connect with <FacebookIcon className={styles.svg} />
                        </div>
                    </div>
                    <div className={styles.submitContainer + " " + styles.mobile} onClick={isLogin ? login : register}>
                        <div className={styles.submit}>
                            <ArrowRight className={styles.arrow} />
                            <SubmitBox className={styles.box} />
                        </div>
                    </div>
                </div> 

              
                
            </div>
            { canShowVideo && <div className={styles.video}>
                <video onLoad={() => setVideoLoaded(true)} loop autoPlay muted style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                    <source src="/video/connect.mp4" type="video/mp4" />
                </video>
            </div> }
        </div>
    )
}

