import * as React from "react";

function SvgMin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 26" {...props}>
      <g
        stroke="#F0DF73"
        strokeWidth={1.71}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8.695 1.427c-1.938 0-3.42 1.369-3.42 2.965v7.751c0 1.596 1.482 2.964 3.42 2.964s3.42-1.367 3.42-2.963V4.391c0-1.597-1.482-2.965-3.42-2.965z" />
        <path d="M16.39 10.832v1.163c0 4.496-3.445 8.14-7.695 8.14S1 16.49 1 11.995v-1.162M8.695 20.6v3.837m-4.397.35h8.794" />
      </g>
    </svg>
  );
}

export default SvgMin;
