import React from 'react';
import styles from './SocialWidget.module.css';
import SvgAvatar from '../../svg/AvatarIcon';
import SvgGrimoire from '../../svg/GrimoireIcon';

import SvgIconAddFriend from '../../svg/AddFriendIcon';
import SvgIconMic from '../../svg/Mic';
import SvgIconSettings from '../../svg/SettingsIcon';
import SvgIconMail from '../../svg/Mail';
import { useUser } from '../../hooks/firebase';

import Lottie from 'react-lottie-player';
import animationData from './animation.json';
import animArrow from './animArrow.json';

function Friend({ user }) {
    return (
        <div className={styles.friend}>
            <div className={styles.avatar}>
                <SvgAvatar className={styles.avatarIcon} />
            </div>
            <div className={styles.info}>
                <span className={styles.name}>{user.name}</span>
                <div className="flex row align-center">
                    <div className={styles.indicator + " " + styles.online} />
                    <span className={styles.status}>Online</span>
                </div>
            </div>
        </div>
    );
}

export default function SocialWidget({ width, setWide }) {
    const [open, setOpen] = React.useState(() => true); 
    const user = useUser();

    React.useEffect(() => {
        setOpen(width > 1365);
    }, []);

    React.useEffect(() => {
        setWide(open);
    }, [open]);

    return (
        <div className={styles.container + " " + (open ? styles.open : styles.closed)}>
            <div className={styles.container + " " + styles.real + " " + (open ? styles.open : styles.closed)}>
                <div className={styles.profile}>
                    <div className={styles.avatar}>
                        <SvgAvatar className={styles.avatarIcon} />
                    </div>
                    <div className={styles.info}>
                        <span className={styles.name}>{user.name}</span>
                        <div className="flex row align-center">
                            <div className={styles.indicator + " " + styles.online} />
                            <span className={styles.status}>Online</span>
                        </div>
                    </div>
                    <div className={styles.spellbook}>
                        <SvgGrimoire className={styles.icon} />
                    </div>
                </div>

                
                <div className="flex row align-center" style={{ padding: '10px 10px 10px 20px' }}>
                    <span className={styles.friendsLabel}>Friends</span>
                    <div className={styles.line} />
                    <span className={styles.onlineLabel}>0 Online</span>
                    <div className={styles.line} />
                </div>


                <div className={styles.friends}>
                    <div className={styles.notice}>The social presence system is currently offline.</div>
                </div>

                <div className={styles.actions}>
                    <div className={styles.action + " " + styles.disabled}>
                        <SvgIconMail className={styles.icon} />
                    </div>
                    <div className={styles.action + " " + styles.disabled}>
                        <SvgIconAddFriend className={styles.icon} />
                    </div>
                    <div className={styles.action + " " + styles.disabled}>
                        <SvgIconMic className={styles.icon} />
                    </div>
                    <div className={styles.action + " " + styles.disabled}>
                        <SvgIconSettings className={styles.icon} />
                    </div>
                </div>
            </div>

            <div className={styles.x}>
                <div className={styles.test} onClick={() => setOpen(open => !open)}>
                    <Lottie 
                        loop={false} 
                        play={true} 
                        direction={open ? -1 : 1} 
                        animationData={animArrow} 
                        style={{ width: 40, height: 40, transform: 'rotate('+(open ? '' : '-')+'270deg)' }}
                        speed={1}
                    />
                </div>
            </div>
        </div>
    )
}

