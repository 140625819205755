import * as React from "react";

function SvgSettingsIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 29" {...props}>
      <g
        transform="translate(2 2)"
        stroke="#F0DF73"
        strokeWidth={2.28}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <circle strokeLinejoin="bevel" cx={12.54} cy={12.54} r={3.42} />
        <path d="M20.976 15.96a1.881 1.881 0 00.376 2.075l.069.068a2.28 2.28 0 01-1.613 3.895 2.28 2.28 0 01-1.614-.669l-.068-.068a1.881 1.881 0 00-2.075-.376 1.881 1.881 0 00-1.14 1.721v.194a2.28 2.28 0 01-4.56 0v-.103a1.881 1.881 0 00-1.231-1.721 1.881 1.881 0 00-2.075.376l-.068.069a2.28 2.28 0 01-3.895-1.613c0-.606.24-1.186.669-1.614l.068-.068c.538-.55.687-1.371.376-2.075a1.881 1.881 0 00-1.721-1.14H2.28a2.28 2.28 0 010-4.56h.103A1.881 1.881 0 004.104 9.12a1.881 1.881 0 00-.376-2.075l-.069-.068a2.28 2.28 0 011.614-3.895c.605 0 1.185.24 1.613.669l.068.068c.55.538 1.371.687 2.075.376h.091a1.881 1.881 0 001.14-1.721V2.28a2.28 2.28 0 014.56 0v.103c.003.75.45 1.426 1.14 1.721.703.31 1.525.161 2.075-.376l.068-.069a2.28 2.28 0 013.895 1.614 2.28 2.28 0 01-.669 1.613l-.068.068a1.881 1.881 0 00-.376 2.075v.091c.295.69.971 1.137 1.721 1.14h.194a2.28 2.28 0 010 4.56h-.103c-.75.003-1.426.45-1.721 1.14z" />
      </g>
    </svg>
  );
}

export default SvgSettingsIcon;
