import React from 'react';
import Lottie from 'react-lottie-player';
import animIdle from '../../animations/PlayButton/idle.json';
import animHover from '../../animations/PlayButton/hover.json';
import animClick from '../../animations/PlayButton/click.json';
import { useHistory } from 'react-router-dom';
import styles from './PlayButton.module.css';

export default function PlayButton() {
    const [hovering, setHovering] = React.useState(() => false);
    const [clicked, setClicked] = React.useState(() => false);
    const animation = clicked ? animClick : hovering ? animHover : animIdle;
    const looping = !(hovering || clicked);
    const history = useHistory(); 

    React.useEffect(() => {
        if(clicked && !history.location.pathname.startsWith('/play')) {
            setClicked(false);
        }
    }, [history.location.pathname, clicked]);

    return <div onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} onClick={() => {
        history.push('/play');
        setClicked(true)}
    }>
        <Lottie 
            loop={looping} 
            play={true} 
            direction={1} 
            animationData={animation}  
            className={styles.button}
            speed={1}
        />
    </div>
}