import React from 'react';
import { useAuth, useUser } from '../../hooks/firebase';
import { useHistory } from 'react-router-dom'; 

export default function ProtectedContext({ children }) {
    const [isAuthorized, setAuthorized] = React.useState(() => false); 
    const auth = useAuth(); 
    const user = useUser();
    const history = useHistory();

    React.useEffect(() => {
        if(auth.initialized) {
            if(auth.user) {
                if(user) {
                    if(history.location.pathname === '/connect' || history.location.pathname === '/create-profile') {
                        history.push('/');
                    }
                    setAuthorized(true); 
                } else if(user === false) {
                    if(history.location.pathname !== '/create-profile') {
                        history.push('/create-profile')
                        setAuthorized(true); 
                    } else {
                        setAuthorized(true);
                    }
                }
            } else if(auth.user === false) {
                history.push('/connect'); 
            }
        }
        
    }, [user, history, auth.initialized, auth.user]);

    return <>{isAuthorized && children}</>;
}
