import * as React from "react";

function SvgAvatarIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 41 40" {...props}>
      <g fill="#F0DF73" fillRule="nonzero">
        <path d="M34.242 26.409a7.799 7.799 0 00-.796-1.515c-1.59-2.452-4.045-4.075-6.812-4.472-.345-.036-.726.036-1.002.253a8.106 8.106 0 01-4.98 1.695 8.106 8.106 0 01-4.98-1.695 1.253 1.253 0 00-1.002-.253c-2.766.397-5.256 2.02-6.812 4.472a8.987 8.987 0 00-.795 1.515c-.104.216-.07.469.034.685.277.505.623 1.01.934 1.443a13.602 13.602 0 001.59 1.875c.485.505 1.038.974 1.591 1.442a15.244 15.244 0 0020.401-1.442c.554-.577 1.107-1.19 1.591-1.875.346-.47.657-.938.934-1.443.173-.216.207-.469.104-.685z" />
        <path d="M20.65 5c-4.43 0-8.05 3.463-8.05 7.7s3.62 7.7 8.05 7.7c4.43 0 8.05-3.463 8.05-7.7S25.08 5 20.65 5z" />
      </g>
    </svg>
  );
}

export default SvgAvatarIcon;
