import * as React from "react";

function SvgGoogle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 34 34" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M32.618 16.382a18.03 18.03 0 00-.264-3.112h-14.43v5.898h8.263a7.09 7.09 0 01-3.058 4.653v3.827h4.93c2.895-2.667 4.559-6.607 4.559-11.266z"
          fill="#33B3BE"
        />
        <path
          d="M17.876 31.282c4.134 0 7.62-1.35 10.16-3.68l-4.94-3.81c-1.374.93-3.148 1.457-5.215 1.457-3.999 0-7.39-2.683-8.606-6.291H4.178v3.927c2.602 5.148 7.902 8.397 13.698 8.397z"
          fill="#3298A1"
        />
        <path
          d="M9.234 18.965a9.065 9.065 0 010-5.86V9.162H4.088a15.156 15.156 0 000 13.746l5.146-3.943z"
          fill="#44C8D4"
        />
        <path
          d="M17.911 6.66a8.39 8.39 0 015.895 2.285l4.388-4.35A14.84 14.84 0 0017.911.631c-5.81 0-11.124 3.247-13.733 8.398l5.105 3.925c1.214-3.611 4.619-6.293 8.628-6.293z"
          fill="#33B3BE"
        />
      </g>
    </svg>
  );
}

export default SvgGoogle;
