import * as React from "react";

function SvgButtonSquare(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 88 80" {...props}>
      <path
        fillRule="evenodd"
        stroke="#298A92"
        strokeWidth={1.58}
        d="M86.782 1.09H9.868l-8.23 77.42h76.914l8.23-77.42z"
        opacity={0.45}
      />
    </svg>
  );
}

export default SvgButtonSquare;
