import * as React from "react";

function SvgButtonHome(props) {
  return (
    <svg viewBox="0 0 159 48" width="1em" height="1em" {...props}>
      <path
        d="M6.704.15H158.25l-5.846 47.7H.45z"
        fill={props.color || "#747474" }
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgButtonHome;
