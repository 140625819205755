
import React, { useState } from 'react';
import ProtectedContent from '../common/ProtectedContent/ProtectedContent';
import styles from './CreateProfile.module.css';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase';

function PlayButton({ active, className, label, onClick }) {
    var width = "242px";
    var height = "68px";
    var color = active ? "#298A92" : "#747474";
    return (
        <div className={className || ""} style={{ position: 'relative', width, height, marginTop: 25, marginBottom: 25 }} onClick={onClick}>
            <svg style={{ position: 'absolute' }} width={width} height={height} viewBox="0 0 242 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>PlayButton</title>
                <defs>
                    <linearGradient x1="20.4511089%" y1="46.0521822%" x2="82.9712702%" y2="53.9478178%" id="linearGradient-1">
                        <stop stopColor="#298A92" offset="0%"></stop>
                        <stop stopColor="#35BAC5" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="12.6293226%" y1="46.5405361%" x2="87.647038%" y2="52.2343924%" id="linearGradient-2">
                        <stop stopColor="#1B232C" offset="0%"></stop>
                        <stop stopColor="#131313" stopOpacity="0.742460664" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="20.4511089%" y1="46.5405361%" x2="82.9712702%" y2="53.4594639%" id="linearGradient-3">
                        <stop stopColor="#298A92" offset="0%"></stop>
                        <stop stopColor="#35BAC5" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="Current-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Home-News-FINALE" transform="translate(-45.000000, -7.000000)">
                        <g id="PlayButton" transform="translate(45.000000, 7.000000)">
                            <path d="M18.2526439,0.526893529 L0.680581243,67.475 L223.226557,67.475 L241.312403,1.56795044 L18.2526439,0.526893529 Z" id="Rectangle-Copy" stroke="url(#linearGradient-1)" strokeWidth="1.05" opacity="0.199999988"></path>
                            <path d="M228.938447,7 L27.5485608,7 L13.5830436,61 L215.438447,61 L228.938447,7 Z" id="Rectangle" stroke="url(#linearGradient-3)" strokeWidth="4" fill="url(#linearGradient-2)"></path>
                        </g>
                    </g>
                </g>
            </svg>

            <div style={{ cursor: 'pointer', width: '100%', height: '100%', display: 'flex', position: "absolute", alignItems: 'center', justifyContent: 'center' }}>
                <span style={{ fontSize: '1.3em'}}>{label || ""}</span>
            </div>
        </div>
    )
    
}

function Component({ setPageReady }) {
    const [error, setError] = useState(() => '');
    const [name, setName] = useState(() => '');
    const history = useHistory();

    React.useEffect(() => {
        setPageReady(true); 
    }, [setPageReady]);

    const createProfile = async (e) => {
        e.preventDefault();
        try {

            let token = await firebase.auth().currentUser.getIdToken(false);

            let response = await fetch('https://us-central1-arcmages.cloudfunctions.net/createProfile', {
                method: 'POST',
                body: JSON.stringify({ name, token })
            });

            let data = await response.json();

            if(response.status == 200 && !data.code) {
                history.push('/');
            } else {
                setError(data.message);  
            }
        } catch (e) {
            console.log('error:', e);
            setError(e.message); 
        }
    }

    return (
        <div className={styles.page}>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h1 className={styles.label}>CHARACTER NAME</h1>
                    <span className={styles.error}>{error}</span>
                    <input type="text" className={styles.input} placeholder="Between 3 and 12 characters" value={name} onChange={e => setName(e.target.value)} />
                    <PlayButton label="Create" onClick={createProfile} />
                </div>
            </div>  
        </div>
    )
}

export default function Home(props) {
    return <ProtectedContent>
        <Component {...props} />
    </ProtectedContent>;    
}