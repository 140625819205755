import * as React from "react";

function SvgCartIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 28" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        transform="translate(.1 2.2)"
      >
        <path strokeWidth={1.26} d="M.9 0h4.5l1 15.3h12.5" />
        <path strokeWidth={1.26} d="M9 3.6h15.3l-2.893 8.1H9.956" />
        <ellipse
          cx={3.43}
          cy={21.56}
          strokeWidth={0.9}
          rx={1.51}
          ry={2}
          transform="rotate(35 3.43 21.56)"
        />
        <ellipse
          cx={16.17}
          cy={21.56}
          strokeWidth={0.9}
          rx={1.51}
          ry={2}
          transform="rotate(35 16.17 21.56)"
        />
      </g>
    </svg>
  );
}

export default SvgCartIcon;
