import AppearanceSlots from '../../common/Character/AppearanceSlots.json';
import NullableSlots from '../../common/Character/NullableSlots.json';

var assets = {}; 

var remaining = ["Back", "Eyes", "Hair", "HairBack", "Hat", "Head", "MainHand", "Mouth", "Neck", "OffHand", "Shoes", "Torso"]; 
remaining.forEach(category => assets[AppearanceSlots[category]] = []);

while(remaining.filter(category => category && category).length) {
    remaining.forEach(category => {
        if(!category) return; 
        const slot = AppearanceSlots[category];
        try {
            const asset = require(`./${category}_${Object.values(assets[slot]).length + 1}.jpg`); 
            assets[slot].push(asset); 
        } catch (e) {
            delete remaining[slot]; 
        }
    });
}

export default assets;