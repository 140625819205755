import * as React from "react";

function SvgHomeIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 28" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.35}
      >
        <path d="M3.45 9.49l11.7-8.19 9.9 8.19-1.8 15.57H1.65l1.8-15.57z" />
        <path d="M8.85 24.7l1.8-12.6h7.2l-1.8 12.6" />
      </g>
    </svg>
  );
}

export default SvgHomeIcon;
