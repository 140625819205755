import React from 'react';
import Connect from '../common/Connect/Connect';
import { useAuth, useUser } from '../hooks/firebase';
import { useHistory } from 'react-router-dom'; 

function Component({ setPageReady }) {
    const auth = useAuth(); 
    const user = useUser();
    const history = useHistory();

    React.useEffect(() => {
        if(auth.initialized) {
            if(auth.user === false) {
                setPageReady(true);
            } else if(auth.user) {
                if(user !== undefined) {
                    if(user == null) {
                        history.push('/create-profile');
                    } else {
                        history.push('/');  
                    }
                }
            }
        }
    }, [user, history, setPageReady, auth.initialized, auth.user]);

    return (
        <Connect />
    )
}

export default function ConnectPage({ setPageReady }) {
    return <Component setPageReady={setPageReady} />
}
