import * as React from "react";

function SvgAddFriendIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <g fill="#F0DF73" fillRule="nonzero">
        <path d="M22 16c3.312 0 6 2.688 6 6 0 3.313-2.688 6-6 6s-6-2.687-6-6 2.688-6 6-6zm3.504 6.9v-1.8H22.9v-2.604h-1.8v2.603h-2.604v1.8H21.1v2.604h1.8V22.9h2.604z" />
        <path d="M7.163 15a9.693 9.693 0 009.237 0c.538.035 1.079.13 1.6.278-3.812 2.608-4.76 7.765-2.122 11.52.21.296.432.574.671.84a31.608 31.608 0 01-14.02-1.023A3.56 3.56 0 010 23.22c0-2.029.707-3.997 1.996-5.582A7.223 7.223 0 017.163 15zM11 0c3.867 0 7 3.132 7 7 0 3.867-3.133 7-7 7-3.868 0-7-3.133-7-7 0-3.868 3.133-7 7-7z" />
      </g>
    </svg>
  );
}

export default SvgAddFriendIcon;
