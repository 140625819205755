import * as React from "react";

function SvgMail(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 14" {...props}>
      <path
        d="M16.418 0H1.582C.711 0 0 .725 0 1.615v10.77C0 13.272.708 14 1.582 14h14.836c.869 0 1.582-.723 1.582-1.615V1.615C18 .728 17.292 0 16.418 0zM16 1L9.03 8 2 1h14zM1 12V2l5 4.978L1 12zm1 1l4.942-5 1.731 1.737c.2.201.524.2.724-.002l1.688-1.708L16 13H2zm15-1l-5-5 5-5v10z"
        fill="#F0DF73"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgMail;
