import React from 'react';
import styles from './Home.module.css';
import { Switch, Route, useHistory } from 'react-router-dom'
import SocialWidget from '../SocialWidget/SocialWidget';
import Navigation from '../Navigation/Navigation';
import Character from '../Character/Character';
import animationData from './animation.json'
import Lottie from 'react-lottie-player';

import ComingText from '../../svg/ComingText';
import SoonText from '../../svg/SoonText';
import Logo from '../../svg/Logo';
import { useUser } from '../../hooks/firebase';

import skin from './Contributor.png';
import skin2 from './Adopter.png';

function HomeComponent() {
    let [dots, setDots] = React.useState(() => 0);
    let user = useUser();
    let history = useHistory();


    React.useEffect(() => {
        setDots(1); 
    }, []);

    React.useEffect(() => {
        setTimeout(() => {
            setDots(prev => (prev + 1 > 3 ? 0 : prev + 1));
        }, 750);
    }, [dots]);

    let string = [...Array(dots)].map((_, i) => '.');

    return <div>
        <div className="flex row space-between">
            <div className={styles.spectate}>
                <Lottie 
                    loop={true} 
                    play={true} 
                    animationData={animationData} 
                    style={{ width: '80%', height: '80%' }}
                    speed={1}
                />
                <div style={{ position: 'relative', marginTop: 20 }}>
                    <span className={styles.label} style={{ width: '100%' }}>Waiting on games to spectate</span>
                    <span style={{ position: 'absolute', bottom: 2, right: -31, direction: 'ltr', width: 30, fontSize: '1.2em'}}>{string}</span>
                </div>
            </div>
            <div className={styles.offers}>
                    <h1 className={styles.header}>SPECIAL OFFERS</h1>
                <div className={styles.container}>
                    <div className={styles.item}>
                        <div className={styles.name + " " + "mobile"}>CONTRIBUTOR</div>
                        <img className={styles.preview} src={skin} />
                        <div className={styles.info}>
                            <div className={styles.name + " web"}>CONTRIBUTOR</div>
                            <div className={styles.description}>Support ArcMages Development</div>
                            <div style={{ flex: 1 }} />
                            <div onClick={() => window.open('https://www.patreon.com/join/RP1Gaming/checkout?rid=2922253', '_blank')}><button className={styles.purchase + " " + styles.premium}>Become a Contributor</button></div>
                            <div onClick={() => history.push('/character')}><button className={styles.purchase}>Demo Skin</button></div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.name + " " + "mobile"}>EARLY ADOPTER</div>
                        <img className={styles.preview} src={skin2} />
                        <div className={styles.info}>
                            <div className={styles.name + " web"}>EARLY ADOPTER</div>
                            <div className={styles.description}>Limited Edition Skin, Avatar & Title.</div>
                            <div style={{ flex: 1 }} />
                            <div onClick={() => {
                                if(!user.adoptor) window.open('https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PLECTC9U2YFEN&custom='+user.id, '_blank')
                            }}><button className={styles.purchase + " " + styles.premium}>{user.adoptor ? 'Already Owned' : 'Purchase Skin ($29.95)'}</button></div>
                            <div onClick={() => history.push('/character')}><button className={styles.purchase}>Demo Skin</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.news}>
            <SoonText  className={styles.soon}/>
            <Logo  className={styles.logo}/>
        </div>
    </div>
}

function Play() {
    return <div className={styles.play}>
        <div className={styles.comingSoon}>COMING SOON</div>
        <div className={styles.playInfo}>
            Thank you for showing interest in ArcMages <span className={styles.highlight}>BETA</span>.<br/>
            We're currently working around the clock to bring you the best experience possible so thank you for your patience and continued support.<br /><br />
            The <span className={styles.highlight}>BETA</span> will be limited access, if you're seeing this page then you're already registered to particpate. When the time comes we will be <span className={styles.highlight}>randomly selecting a small amount of users</span> to assist us with testing to ensure that the product is ready for the general population. <span className={styles.highlight}>Patreon Contributors</span> will be <span className={styles.highlight}>guaranteed</span> a spot in the upcoming <span className={styles.highlight}>BETA</span> as well as <span className={styles.highlight}>all future tests</span>. Once we open up user profiles (Design Pending) you will be able to link your Patreon & Discord accounts to collect rewards.
        </div>
        <div onClick={() => window.open('https://www.patreon.com/join/RP1Gaming/checkout?rid=2922253', '_blank')} className={styles.contribute}>BECOME A CONTRIBUTOR</div>
    </div>
}

export default function Home({ width }) {
    const [wide, setWide] = React.useState(() => false);
    return <>
        <div className={styles.page}>
            <div className={styles.overlay} />
            <div className={styles.wrapper + " " + (wide ? styles.wide : undefined)}>
                <Navigation />
                <div className={styles.content}>
                    <Switch>
                        <Route path="/play" component={Play} />
                        <Route path="/character" component={Character} />
                        <Route component={HomeComponent} />
                    </Switch>
                </div>
            </div>
            <SocialWidget width={width} setWide={setWide} />
        </div>
    </>
}

// Make the pages inside the 'container' where the shell of the website
// is outside of it so we can handle updates seperately. 

/**
 * <Providers>
 *   <Content>bn  
 *     <Navigation />
 *     <BrowserRouter /> (Internal?)
 *   </Content>
 *   <Social />
 * </Providers>
 * 
 * export default memo(Modal, (prevState, nextState) => prevState.show === nextState.show) ;

 */