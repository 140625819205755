import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import ConnectPage from './pages/ConnectPage';
import Loading from './common/Loading/Loading';
import { AuthProvider, UserProvider } from './hooks/firebase';
import Home from './pages/Home';
import CreateProfile from './pages/CreateProfile';


function WebApplication() {
    const [pageReady, setPageReady] = React.useState(() => false);
    const [isLoadingComplete, setLoadingComplete] = React.useState(() => false);
    const [loadingStyle, setLoadingStyle] = React.useState(() => ''); 
    const [showLoading, setShowLoading] = React.useState(() => true); 
    React.useEffect(() => {
        var complete = isLoadingComplete && pageReady; 
        if(complete) {
            setLoadingStyle('fade');
            setTimeout(() => {
                setShowLoading(false); 
            }, 600);
        }
    }, [isLoadingComplete, pageReady]);

    return (
        <>
            <BrowserRouter> 
                <Switch>
                    <Route exact path="/connect" render={props => <ConnectPage {...props} setPageReady={setPageReady} />} />
                    <Route exact path="/create-profile" render={props => <CreateProfile {...props} setPageReady={setPageReady} />} />
                    <Route render={props => <Home {...props} setPageReady={setPageReady} />} />
                </Switch>
            </BrowserRouter>

            { showLoading && (
                <Loading 
                    className={loadingStyle}
                    loaded={pageReady} 
                    timer={1500}
                    timerCompletion={70}
                    onComplete={() => setLoadingComplete(true)} 
                />
            ) }
        </>
    )
};

export default function Wrapper() {
    return (
        <AuthProvider>
            <UserProvider>
                <WebApplication />
            </UserProvider>
        </AuthProvider>
    )
}