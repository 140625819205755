import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from './animation.json';
import styles from './Loading.module.css';

export default function Loading({ onComplete, loaded, timer, timerCompletion, className }) {
    const currentFrame = React.useRef(0); 
    const [targetFrame, setTargetFrame] = React.useState(() => 1);
    const [timerPercentage, setTimerPercentage] = React.useState(() => 0);
    const [complete, setComplete] = React.useState(() => false);

    React.useEffect(() => {
        currentFrame.current = targetFrame;
    }, [targetFrame]);


    React.useEffect(() => {
        var result = ((timerPercentage / 100) * animationData.op);
        var _loaded = loaded ? ((30 / 100) * animationData.op) : 0;
        var value = Math.round(result + _loaded); 
        if(value !== targetFrame) setTargetFrame(prev => Math.round(result + _loaded));
    }, [timerPercentage, loaded, targetFrame]);

    
    React.useEffect(() => {
        if(timerPercentage < timerCompletion) {
            setTimeout(() => {
                setTimerPercentage(prev => prev + 1); 
            }, (timer / timerCompletion));
        }
    }, [timer, timerPercentage, timerCompletion]);

    
    return <div className={styles.container + ' ' + className}>
        <Lottie 
            className={styles.animation + ' ' + (complete ? styles.complete : '')}
            loop={false} 
            play={true} 
            direction={1} 
            animationData={animationData} 
            segments={[currentFrame.current, targetFrame]} 
            style={{ width: 400, height: 400 }}
            speed={1}
            onSegmentStart={(a, b, c) => {
                var total = a.firstFrame + a.totalFrames; 
                if(total === animationData.op) {
                    setComplete(true);
                    onComplete();
                }
            }}
        />
    </div>
}
