import * as React from "react";

function SvgGrimoireIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 28" {...props}>
      <g fill="#F0DF73" fillRule="nonzero">
        <path d="M19.958.4H2.442C1.535.4.8 1.13.8 2.032v23.936c0 .901.735 1.632 1.642 1.632h17.516c.907 0 1.642-.73 1.642-1.632V2.032C21.6 1.131 20.865.4 19.958.4zM1.895 2.032c0-.3.245-.544.547-.544h2.14a3.279 3.279 0 01-2.687 2.671V2.032zm.547 24.48a.546.546 0 01-.547-.544v-2.127a3.279 3.279 0 012.687 2.671h-2.14zm18.063-.544c0 .3-.245.544-.547.544h-2.14a3.279 3.279 0 012.687-2.671v2.127zm0-3.226a4.37 4.37 0 00-3.794 3.77H5.69a4.37 4.37 0 00-3.794-3.77V5.258a4.37 4.37 0 003.794-3.77H16.71a4.37 4.37 0 003.794 3.77v17.484zm0-18.583a3.279 3.279 0 01-2.687-2.671h2.14c.302 0 .547.244.547.544v2.127z" />
        <path d="M17.055 6.8H6.145a.544.544 0 00-.545.543v14.114c0 .3.244.543.545.543h10.91a.544.544 0 00.545-.543V7.343c0-.3-.244-.543-.545-.543zm-.546 14.114H6.691V7.886h9.818v13.028z" />
        <path d="M8.533 14.4c.172 0 .252.077.48.384.278.507.77.815 1.299.815.529 0 1.02-.308 1.298-.815.213-.29.3-.384.48-.384s.267.093.48.384c.29.49.773.795 1.297.816.294 0 .533-.269.533-.6 0-.331-.239-.6-.533-.6-.18 0-.267-.093-.48-.384-.278-.507-.77-.816-1.3-.816-.529 0-1.02.309-1.298.816-.214.29-.3.384-.48.384-.171 0-.251-.077-.48-.384-.29-.491-.772-.795-1.296-.816-.294 0-.533.269-.533.6 0 .332.239.6.533.6z" />
      </g>
    </svg>
  );
}

export default SvgGrimoireIcon;
