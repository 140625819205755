import * as React from "react";

function SvgArrowRightIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 28" {...props}>
      <path
        fill="none"
        stroke="#f0df73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.58}
        d="M1.57 13.8h23.474M14.21 1.16L26.85 13.8 14.21 26.44"
      />
    </svg>
  );
}

export default SvgArrowRightIcon;
