
import React from 'react';
import ProtectedContent from '../common/ProtectedContent/ProtectedContent';
import Web from '../web/Home/Home';
import Mobile from '../mobile/Home/Home';
import styles from './Home.module.css';
import Lottie from 'react-lottie-player';
import animationData from './animation.json';

function Component({ setPageReady }) {
    const [width, setWidth] = React.useState(() => typeof window !== 'undefined' ? window.innerWidth : 0);
    const [height, setHeight] = React.useState(() => typeof window !== 'undefined' ? window.innerHeight : 0)
    React.useEffect(() => {
        setPageReady(true); 
        const onResize = () => {
            setWidth(window.innerWidth); 
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize); 
    }, [setPageReady]);


    // if(width <1366) return (
        // <div className={styles.oops}>
        //     <div className={styles.overlay} />
        //     <div className={styles.content}>
        //         <p className={styles.h}>IN PROGRESS</p>
        //         <p className={styles.info}>We're currently updating the site to be more mobile friendly, in the mean time please check out the full screen version of the application on a desktop browser of your choice.</p>
        //         <p className={styles.info}>You ARE registered for the BETA! We're just working on making the website visible on your screen :)</p>
        //     </div>
        // </div>
    // )

    //1024
    if(width < 958 || height < 600) {
        return <div className={styles.oops}>
            <div className={styles.overlay} />
            <div className={styles.content}>
                <p className={styles.h}>REGISTRATION COMPLETE</p>
                <p className={styles.info}>We're currently updating the site to be more mobile friendly, in the mean time please check out the full screen version of the application on a desktop browser of your choice.</p>
            </div>
        </div>
    }

    if(width <= 420 && height > 550) {
        return <div className={styles.oops}>
            <div className={styles.overlay} />
            <div className={styles.content}>
                <p className={styles.h}>ROTATE DEVICE</p>
                <Lottie loop={true} play={true} animationData={animationData} />
            </div>
        </div>
    }

    // return <div className={styles.oops}>
    //     <div className={styles.overlay} />
    //     <div className={styles.content}>
    //         <p className={styles.h}>UNDERGOING MAINTENANCE</p>
    //         <p className={styles.info}>We're currently doing some maintenance, please check back later.</p>
    //     </div>
    // </div>

    return (
        <Web width={width} />
    )
}

export default function Home(props) {
    return <ProtectedContent>
        <Component {...props} />
    </ProtectedContent>;
}