import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 33 33" {...props}>
      <path
        d="M32.46 16.732C32.46 7.963 25.39.84 16.66.84S.86 7.95.86 16.732c0 7.94 5.78 14.523 13.338 15.708V21.327h-4.016v-4.595h4.003V13.23c0-3.977 2.37-6.189 5.977-6.189 1.725 0 3.529.316 3.529.316v3.911h-1.975c-1.975 0-2.58 1.224-2.58 2.488v2.976h4.37l-.697 4.608h-3.687v11.1c7.571-1.185 13.338-7.768 13.338-15.708"
        fill="#33B3BE"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgFacebook;
