import _ from 'lodash';
import React from 'react';
import AppearanceSlots from '../../common/Character/AppearanceSlots.json';
import NullableSlots from '../../common/Character/NullableSlots.json';
import CharacterAssets from '../../common/Character/assets';
import SvgArrow from '../../svg/ArrowRightIcon';
import { useUser } from '../../hooks/firebase';
import styles from './Character.module.css';
import { useHistory, Prompt } from 'react-router-dom';
import ChooseAsset from './ChooseAsset';
import * as firebase from 'firebase';


function Category({ name, override, setSelected }) {
    return <div className={styles.category} onClick={() => setSelected(AppearanceSlots[name])}>
        <img src={CharacterAssets[AppearanceSlots[name]][0]} alt="" />
        <p className={styles.label}>{override || name}</p>
    </div>
}


function SelectCategory({ setSelected }) {
    return <>
        <div className={styles.row}>
            <Category name="Hat" setSelected={setSelected} />
            <Category name="Hair" setSelected={setSelected} />
            <Category name="HairBack" override="Hair (Extra)" setSelected={setSelected} />
            <Category name="Head" setSelected={setSelected} />
        </div>
        <div className={styles.row}>
            <Category name="Eyes" setSelected={setSelected} />
            <Category name="Mouth" setSelected={setSelected} />
            <Category name="Torso" setSelected={setSelected} />
            <Category name="Neck" setSelected={setSelected} />
        </div>
        <div className={styles.row}>
            <Category name="Back" setSelected={setSelected} />
            <Category name="MainHand" override="Mainhand" setSelected={setSelected} />
            <Category name="OffHand" override="Offhand" setSelected={setSelected} />
            <Category name="Shoes" setSelected={setSelected} />
        </div>
    </>
}



export default function CharacterScreen() {
    const [ready, setReady] = React.useState(() => false); 
    const [appearance, setAppearance] = React.useState(() => {}); 
    const [selected, setSelected] = React.useState(() => null);
    const [updateTintColor, setUpdateTintColor] = React.useState(() => null);
    const [canUpdateTints, setCanUpdateTints] = React.useState(() => false); 
    const [canPush, setCanPush] = React.useState(() => {});
    const [lastUpdate, setLastUpdate] = React.useState(() => undefined); 
    const [usingPreviews, setUsingPreviews] = React.useState(() => false); 
    const User = useUser(); 
    const { name, title } = User; 

    const history = useHistory();

    const canSave = !_.isEqual(User.appearance, appearance);
    const unsavedChanges = canSave;

    if(unsavedChanges) window.onbeforeunload = () => 'You have unsaved changes, are you sure you want to leave?';
    else window.onbeforeunload = undefined;

    React.useEffect(() => {
        window.onmessage = function(e) {
            if(e.origin === "https://playcanv.as" && e.isTrusted && e.data === "ready") {
                setReady(true); 
            }
        }

        setInterval(() => {
            setCanUpdateTints(true); 
        }, 50);

        setInterval(() => {
            setCanPush(true); 
        }, 250);
    }, []);

    React.useEffect(() => {
        if(!appearance) {
            setLastUpdate(_.cloneDeep(User.appearance));
            setAppearance(_.cloneDeep(User.appearance));
        }
    }, [User.appearance]);

    React.useEffect(() => {
        if(appearance) {
            let value = false; 
            Object.keys(appearance).forEach(slot => {
                let id = appearance[slot].id;
                if(!User.cosmeticUnlocks[slot][id]) value = true;
            });
            setUsingPreviews(value); 
        }
    }, [appearance]);

    React.useEffect(() => {
        if(canUpdateTints && updateTintColor) {
            setCanUpdateTints(false); 
            setAppearance(prev => {
                let app = Object.assign({}, prev); 
                let { slot, key, rgb } = updateTintColor;
                let _slot = app[slot];
                _slot[key] = rgb;
                setUpdateTintColor(prev =>  JSON.stringify(prev) === JSON.stringify(updateTintColor) ? null : prev); 
                return app;
            });
        }
    }, [canUpdateTints, updateTintColor])


    React.useEffect(() => {
        if(ready) {
            const app = [];
            const tints = [];

            Object.keys(appearance).forEach(slot => {
                const data = appearance[slot]; 
                const colors = [data.primary.concat(1), data.secondary.concat(1), data.tertiary.concat(1)]; 
                app[slot] = data.id;
                tints[slot] = colors; 
            });

            const element = document.getElementById('cc'); 
            if(element && element.contentWindow && element.contentWindow.postMessage) {
                element.contentWindow.postMessage({ type: 'cc/sa', appearance: app, tints }, 'https://playcanv.as');
            }
        }
    }, [ready, appearance]); 

    React.useEffect(() => {
        if(canPush && !_.isEqual(lastUpdate, appearance)) {
            setLastUpdate(_.cloneDeep(appearance)); 
            setCanPush(false);


            Object.keys(appearance).forEach(slot => {
                if(!_.isEqual(lastUpdate[slot], appearance[slot])) {
                    let { id, primary, secondary, tertiary } = appearance[slot]; 
                    // update(slot, id, primary, secondary, tertiary); 
                }
            });

            // update(slot, id, primary, secondarty, tertiary); 
        }
    }, [canPush, lastUpdate, appearance]);

    const goBack = () => {
        if(selected != null) return setSelected(null); 
        else history.goBack();
    }

    const save = async () => {
        let token = await firebase.auth().currentUser.getIdToken();

        Object.keys(appearance).forEach(async slot => {
            if(_.isEqual(appearance[slot], User.appearance[slot])) return; 
            const { id, primary, secondary, tertiary } = appearance[slot]; 
            fetch('https://us-central1-arcmages.cloudfunctions.net/updateCharacterAppearance', {
                method: 'POST',
                body: JSON.stringify({ token, slot, id, primary, secondary, tertiary })
            });
        });
    }
    
    const update = async (slot, id) => {
        // https://us-central1-arcmages.cloudfunctions.net/updateCharacterAppearance
        try {
            let token = await firebase.auth().currentUser.getIdToken();


            setAppearance(prev => {
                let app = Object.assign({}, prev); 
                let _slot = app[slot];
                _slot.id = id; 
                return app;
            });

            // let response = await fetch('https://us-central1-arcmages.cloudfunctions.net/updateCharacterAppearance', {
            //     method: 'POST',
            //     body: JSON.stringify({ token, slot, id, primary, secondary, tertiary })
            // });

        } catch (e) {
            console.error(e); 
        }
    }

    const updateTint = (slot, key, rgb) => {
        setUpdateTintColor(prev => ({ slot, key, rgb }));
    }

    return (
        <div className="flex grow column">
            <div className="flex row align-center" style={{ marginTop: 20 }}>
                <div className="flex row align-center" style={{ cursor: 'pointer' }} onClick={goBack}>
                    <div className={styles.back}>
                        <SvgArrow className={styles.arrow} />
                    </div>
                    
                    <span className={styles.backLabel}>Go Back</span>
                </div>
            </div>

            <div className="flex row align-center" style={{ marginTop: 40 }}>
                <div>
                    <div className={styles.characterBox}>
                        <iframe id="cc" className={styles.playcanvas} src="https://playcanv.as/e/b/drr7j6HL/"/>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.title}>{title}</div>
                    </div>
                        <>
                            <div className={styles.save + " " + (canSave ? undefined : styles.disabled)} onClick={save}>Save</div>
                            <div className={styles.savePreview}>{canSave && usingPreviews && 'Previewed items will be  unequipped on Sept. 20th'}</div>
                        </>
                </div>

                <div className={styles.categories}>
                    { selected == null && <SelectCategory setSelected={setSelected} /> }
                    { appearance != null && selected != null && <ChooseAsset slot={selected} selected={appearance[selected]} unlocks={User.cosmeticUnlocks} update={update} updateTint={updateTint} /> }
                </div>
            </div>

            <Prompt when={unsavedChanges} message='You have unsaved changes, are you sure you want to leave?' />
        </div>
    )
}