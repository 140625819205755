import * as React from "react";

function SvgProfileIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 21" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        transform="translate(1 1.25)"
      >
        <path d="M16 18v-2c0-2.21-1.79-4-4-4H4c-2.21 0-4 1.79-4 4v2" />
        <circle cx={8} cy={4} r={4} />
      </g>
    </svg>
  );
}

export default SvgProfileIcon;
