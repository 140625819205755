import React from 'react';
import styles from './Navigation.module.css';
import SvgButtonHome from '../../svg/ButtonHome';
import PlayButton from '../../common/PlayButton/PlayButton';
import SvgHomeIcon from '../../svg/HomeIcon';
import SvgCartIcon from '../../svg/CartIcon';
import SvgPersonIcon from '../../svg/ProfileIcon';
import { useHistory } from 'react-router-dom'; 
import { useAuth } from '../../hooks/firebase';

function Link({ active, href, first, Icon, disabled, label, small }) {
    const history = useHistory();
    return (
        <div className={styles.link + " " + (first ? styles.first : undefined) + " " + (disabled ? styles.disabled : undefined)} onClick={() => disabled ? {} : history.push(href)}>
            <SvgButtonHome className={styles.bg} color={ active ? "#298A92" : undefined } />
            <div className={styles.content}>
                <Icon className={styles.icon + " " + (small ? styles.small : undefined)} />
                <span className={styles.label}>{label}</span>
            </div>
        </div>
    )
}

export default function Navigation() {
    const history = useHistory();
    const auth = useAuth();

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <PlayButton />
                <Link Icon={SvgHomeIcon} href="/" first label="HOME" active={history.location.pathname === '/'} />
                <Link Icon={SvgCartIcon} disabled href="/shop" label="SHOP" active={history.location.pathname.startsWith('/shop')} />
                <Link Icon={SvgPersonIcon} href="/character" small label="CHARACTER" active={history.location.pathname.startsWith('/character')}/>
            </div>
        </div>
    )
}