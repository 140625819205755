import React from 'react';
import styles from './Character.module.css';

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}



export default function ColorPicker({ value: initialValue, label, updateTint }) {
    const [value, setValue] = React.useState(() => (rgbToHex(initialValue[0] * 255, initialValue[1] * 255, initialValue[2] * 255)));
    const [rawValue, setRawValue] = React.useState(() => rgbToHex(initialValue[0] * 255, initialValue[1] * 255, initialValue[2] * 255));

    React.useEffect(() => {
        if(value !== rgbToHex(initialValue[0] * 255, initialValue[1] * 255, initialValue[2] * 255)) {
            let rgb = hexToRgb(value); 
            updateTint([rgb.r / 255, rgb.g / 255, rgb.b / 255]);
        }

        setRawValue(value);
    }, [value]);

    let invert = false;
    let hex = hexToRgb(value);
    if(hex.r*0.299 + hex.g *0.587 + hex.b*0.114 < 186) invert = true; 

    return <div className={styles.colorPicker}>
        <div className={styles.colorContainer}>
            <input className={styles.color} type="color" value={value} onChange={e => setValue(e.target.value)} /> 
            <div className={styles.label}>{label}</div>
        </div>
        <input className={styles.input} type="text" value={rawValue} onChange={e => {
            let v = e.target.value; 
            if(v.charAt(0) !== '#') v = '#'+ v; 
            hexToRgb(v) != null ? setValue(v) : setRawValue(v);
        }}/>
    </div>
}


/**
 * const buildPallete = () => {
        let gradient = context.createLinearGradient(0, 0, width, 0); 
        gradient.addColorStop(0, 'rgb(255, 0, 0)');
        gradient.addColorStop(0.15, 'rgb(255, 0, 255)');
        gradient.addColorStop(0.33, 'rgb(0, 0, 255)');
        gradient.addColorStop(0.49, 'rgb(0, 255, 255)');
        gradient.addColorStop(0.67, 'rgb(0, 255, 0)');
        gradient.addColorStop(0.84, 'rgb(255, 255, 0)');
        gradient.addColorStop(1, 'rgb(255, 0, 0)');

        context.fillStyle = gradient;
        context.fillRect(0, 0, width, height); 

        gradient = context.createLinearGradient(0, 0, 0, height); 
        gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
        gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)');
        gradient.addColorStop(0.5, 'rgba(0, 0, 0, 0)');
        gradient.addColorStop(1, 'rgba(0, 0, 0, 1)');

        context.fillStyle = gradient;
        context.fillRect(0, 0, width, height); 
    };

    const onMouseDown = (e) => {
        setIsDragging(true); 
    };

    const onMouseUp = (e) => {
        setIsDragging(false); 
    };

    const onMouseMove = (e) => {
        if(isDragging) {
            var position = findPos(ref.current); 
            var x = e.pageX - position.x;
            var y = e.pageY - position.y; 
            var imageData = context.getImageData(x, y, 1, 1).data; 
            // var color = `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`;
            setColor({ r: imageData[0], g: imageData[1], b: imageData[2] });
        }
    };

 */